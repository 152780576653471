import { Route, Routes } from 'react-router-dom';
import { DashboardTable } from './DashboardTable';
import { DashboardItemDetail } from './DashboardItemDetail';

export const DashboardApp = () => {
  return (
    <Routes>
      <Route path="/dashboard" element={<DashboardTable />} />
      <Route path="/dashboard/:id" element={<DashboardItemDetail />} />
    </Routes>
  );
};

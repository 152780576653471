import { useCallback, useEffect, useRef, useState } from 'react';
import { DialogDto } from '../../../../../apis/conversations/interfaces';
import Avatar from './components/avatar';
import { Response } from './components/response';
import { useRecoilValue } from 'recoil';
import { asyncDialogAtom } from '../../../../../recoil/atoms/atoms';
import RateButton from './components/rate_button';
import { createWidget, getDashboard } from '../../../../../apis/dashboard/apis';
import { Form } from 'antd';
import { WidgetCreationModal } from './components/WidgetCreationModal';
import { CreateWidgetDto } from '../../../../../apis/dashboard/interfaces';
import Swal from 'sweetalert2';

interface ChatHistoryProps {
  dialogs: DialogDto[] | undefined;
  sentInput: string;
  isLoading: boolean;
  profileLink?: string;
  username: string;
  regenerateQueryAsync: (updatedQuery: string, dialogId: string) => void;
}

export default function ChatHistory({
  dialogs,
  isLoading,
  sentInput,
  profileLink,
  username,
  regenerateQueryAsync,
}: ChatHistoryProps) {
  const asyncDialog = useRecoilValue<DialogDto>(asyncDialogAtom);
  const dialogEndRef = useRef(null);
  const previousDialogLength = useRef(dialogs?.length || 0);
  const [form] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dialogId, setDialogId] = useState('');

  const scrollToBottom = () => {
    dialogEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });
  };

  useEffect(() => {
    if (dialogs?.length !== 0) {
      scrollToBottom();
    }
  }, []);

  useEffect(() => {
    if (isLoading && sentInput) {
      scrollToBottom();
    }
    previousDialogLength.current = dialogs?.length || 0;
  }, [isLoading, sentInput]);

  const canAddWidget = (dialog: DialogDto) => {
    // Return index of the respons that has ai_chart
    let sqlQueryPresent = false;
    let chartPresent = false;
    let res = -1;

    dialog?.responses?.forEach((response, index) => {
      if (response.sql_query) {
        sqlQueryPresent = true;
      }
      if (response.ai_chart) {
        chartPresent = true;
        res = index;
      }
    });

    if (sqlQueryPresent && chartPresent) {
      return res;
    } else {
      return -1;
    }
  };

  const returnAddToDashboardButton = (dialog: DialogDto) => {
    const index = canAddWidget(dialog);
    if (index !== -1) {
      return (
        <div key={index} className="relative">
          <div className="absolute top-2 right-2 z-10">
            <button
              onClick={() => handleModalOpen(dialog.dialogId)}
              className="inline-flex items-center px-3 py-2 text-sm font-medium text-white bg-black rounded-md hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black"
            >
              <svg
                className="w-4 h-4 mr-2"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 4v16m8-8H4"
                />
              </svg>
              Add to Dashboard
            </button>
          </div>
        </div>
      );
    } else {
      return null;
    }
  };

  const handleModalOpen = (dialogId: string) => {
    setIsModalOpen(true);
    setDialogId(dialogId);
  };

  const handleModalCancel = () => {
    setDialogId('');
    setIsModalOpen(false);
    form.resetFields();
  };

  const handleCreateWidget = async (createWidgetDto: CreateWidgetDto) => {
    try {
      await createWidget(createWidgetDto);
    } catch (error) {
      console.error('Failed to create widget:', error);
    }
  };

  const handleRegenerateQuery = async (
    query: string,
    dialogId: string
  ): Promise<void> => {
    if (query.trim()) {
      return regenerateQueryAsync(query, dialogId);
    } else {
      console.log('Query is empty');
      return Promise.resolve();
    }
  };

  const checkWidgetExists = async (dashboardId: string, dialogId: string) => {
    const dashboard = await getDashboard(dashboardId);
    if (dashboard.widgets) {
      for (const widget of dashboard.widgets) {
        if (widget.dialogId && widget.dialogId === dialogId) {
          return true;
        }
      }
    }
    return false;
  };

  const showErrorAlert = (message: string) => {
    Swal.fire({
      icon: 'error',
      title: 'Oops...',
      text: 'Something went wrong: ' + message,
    });
  };

  const showSuccessMessage = () => {
    Swal.fire({
      icon: 'success',
      title: 'Success',
      text: 'Widget created successfully',
    });
  };

  const handleModalComplete = () => {
    form
      .validateFields()
      .then(async (values) => {
        form.resetFields();
        const res = await checkWidgetExists(values.dashboard, dialogId);
        if (res) {
          throw new Error('Widget already exists in dashboard');
        }
        const createWidgetDto: CreateWidgetDto = {
          name: values.name,
          dashboardId: values.dashboard,
          userInput: values.userInput,
          refreshRate: values.refreshRate,
          dialogId: dialogId,
        };
        await handleCreateWidget(createWidgetDto);
        showSuccessMessage();
      })
      .catch((error) => {
        showErrorAlert(error.message);
      })
      .finally(() => {
        setDialogId('');
        setIsModalOpen(false);
        form.resetFields();
      });
  };

  // TODO: Implement event listener to change dialog request
  const renderDialogs = useCallback(
    (dialogs: DialogDto[], isAsyncDialog: boolean) => {
      return dialogs?.map((dialog) => {
        return (
          <div key={'dialog' + dialog?.dialogId}>
            <div className="flex w-full min-h-20 h-auto border-b-2 border-b-border_selected">
              <Avatar
                isLegion={false}
                profileLink={profileLink}
                username={username}
                isLoading={isAsyncDialog}
              />
              <div className="flex-1 max-w-fit my-4 p-3 h-auto text-text_unselected text-base text-balance bg-layer2 rounded-md">
                <span>
                  {isLoading && isAsyncDialog ? sentInput : dialog?.userInput}
                </span>
              </div>
            </div>
            <div className="flex flex-col w-full">
              {returnAddToDashboardButton(dialog)}
              <Response
                responses={dialog?.responses}
                isAsyncDialog={isAsyncDialog}
                onGenerate={(updatedQuery) =>
                  handleRegenerateQuery(updatedQuery, dialog.dialogId)
                }
              />
            </div>
            {!isAsyncDialog && (
              <RateButton
                dialogId={dialog.dialogId}
                dialogRating={dialog.dialogRating}
              />
            )}
          </div>
        );
      });
    },
    [profileLink, username, sentInput]
  );

  return (
    <div className="h-full flex flex-col px-16 overflow-y-auto">
      <WidgetCreationModal
        open={isModalOpen}
        form={form}
        handleModalCancel={handleModalCancel}
        handleModalComplete={handleModalComplete}
      />
      {renderDialogs(dialogs, false)}
      {isLoading && sentInput && renderDialogs([asyncDialog], true)}
      <div ref={dialogEndRef} />
    </div>
  );
}

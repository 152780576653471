import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { authAtom, userMetaAtom } from '../../../../recoil/atoms/atoms';
import { cancelSubscription } from '../../../../apis/billing/apis';
import { Modal } from 'antd';

export interface PricingCardProps {
  title: string;
  price: string;
  features: string[];
  paymentLink?: string;
  isCurrentPlan?: boolean;
}

export const PricingCard: React.FC<PricingCardProps> = (props) => {
  const authInfo = useRecoilValue(authAtom);
  const userMeta = useRecoilValue(userMetaAtom);

  if (!authInfo) return null;

  const handlePlanSwitch = async () => {
    if (props.title === 'Free') {
      Modal.confirm({
        title: 'Switch to Free Plan',
        content: 'Are you sure you want to cancel your current subscription and switch to the Free plan?',
        okText: 'Yes, Switch to Free',
        cancelText: 'No, Keep Current Plan',
        onOk: async () => {
          await cancelSubscription();
          window.location.reload(); // Refresh to show updated plan
        },
      });
    }
  };

  return (
    <div
      className="w-full min-h-[200px] flex flex-col md:flex-row items-start md:items-center gap-6 rounded-lg hover:bg-layer2
    border-2 border-active_hover p-6 text-base text-text_unselected font-mono tracking-tighter"
    >
      <div className="flex flex-col min-w-[200px]">
        <h3 className="my-2">{props.title}</h3>
        <div className="flex items-baseline gap-2">
          <span className="text-text_selected text-3xl font-mono font-black">
            {props.price}
          </span>
          {props.price.startsWith('$') && <span className="text-text_unselected">/ month</span>}
        </div>
      </div>

      <div className="flex-grow">
        <ul className="flex flex-col list-disc ml-6 gap-2">
          {props.features.map((feature, index) => (
            <li key={index} className="text-left break-words">
              {feature}
            </li>
          ))}
        </ul>
      </div>

      <div className="flex-shrink-0 w-full md:w-auto">
        {props.title === 'Free' ? (
          <button
            onClick={handlePlanSwitch}
            className={`w-full md:w-52 h-12 text-center rounded-lg text-text_selected font-bold hover:text-text_unselected ${
              props.isCurrentPlan ? 'bg-binactive' : 'bg-border_unselected'
            }`}
            disabled={props.isCurrentPlan}
          >
            {props.isCurrentPlan ? 'Current Plan' : 'Switch Plan'}
          </button>
        ) : (
          <Link
            to={
              props.paymentLink
                ? `${props.paymentLink}?client_reference_id=${authInfo.username}&prefilled_email=${userMeta?.email || ''}`
                : 'https://www.thelegionai.com/contact'
            }
            target="_blank"
            rel="noopener noreferrer"
            className="block w-full"
          >
            <button
              className={`w-full md:w-52 h-12 text-center rounded-lg text-text_selected font-bold hover:text-text_unselected ${
                props.isCurrentPlan ? 'bg-binactive' : 'bg-border_unselected'
              }`}
              disabled={props.isCurrentPlan}
            >
              {props.isCurrentPlan
                ? 'Current Plan'
                : props.price.startsWith('$')
                  ? 'Switch Plan'
                  : 'Contact Us'}
            </button>
          </Link>
        )}
      </div>
    </div>
  );
};

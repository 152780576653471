import { instance } from '../base/instance';
import { CreateDashboardDto, DashboardDto, UpdateDashboardDto, WidgetDto,CreateWidgetDto } from './interfaces';

export const createDashboard = async (
  dashboard: CreateDashboardDto
): Promise<DashboardDto> => {
  const res = await instance.post('/dashboard', dashboard);
  return res.data;
};

export const getAllDashboards = async (): Promise<DashboardDto[]> => {
  const res = await instance.get('/dashboard');
  return res.data;
};

export const getDashboard = async (id: string): Promise<DashboardDto> => {
  const res = await instance.get(`/dashboard/${id}`);
  return res.data;
};

export const updateDashboard = async (
  id: string,
  data: UpdateDashboardDto
) => {
  const res = await instance.put(`/dashboard/${id}`, data);
  return res.data;
};

export const deleteDashboard = async (id: string): Promise<void> => {
  await instance.delete(`/dashboard/${id}`);
};

export const findOneWithWidgetConfigs = async (
  dashboardId: string
) => {
  const res = await instance.get(`/dashboard/${dashboardId}/widget-configs`);
  return res.data;
};

// export const updateDashboardLayout = async (
//   id: string,
//   layout: Layout[]
// ): Promise<dashboardDto> => {
//   const res = await instance.put(`/dashboard/${id}/layout`, { layout });
//   return res.data;
// };

export const createWidget = async (
  data: CreateWidgetDto
): Promise<WidgetDto> => {
  const res = await instance.post('/widget', data);
  return res.data;
};

export const deleteWidget = async (id: string): Promise<void> => {
  return await instance.delete(`/widget/${id}`);
};

import axios from 'axios';
import { getApiUrl } from '../../config';
export const instance = axios.create({
  baseURL: getApiUrl(),
  headers: {
    'Content-Type': 'application/json',
  },
  timeout: 120 * 1000,
  // Default error handling for network errors, timeouts etc
  transformResponse: [(data) => {
    try {
      return JSON.parse(data);
    } catch (error) {
      return {
        success: false,
        message: 'Failed to parse response data',
        error: error
      };
    }
  }]
});

instance.interceptors.request.use(
  config => {
    const authInfo = JSON.parse(localStorage.getItem('authInfo'));
    if (authInfo) {
      config.headers.Authorization = 'Bearer ' + authInfo.accessToken;
    }
    return config;
  }
);

instance.interceptors.response.use((response) => response, (error) => {
  throw error.response?.data;
});

import { DatabaseType, DatasetType } from '../datasources/interfaces';

export interface SubscriptionResponse {
  id: number;
  subscription_level: string;
  start: Date;
  end: Date;
  stripe_subscription_id: string;
  usage: {
    tokenUsage: number;
    numOfRequests: number;
  };
}

export interface SubscriptionStatus {
  outOfDate: boolean;
  outOfLimit: boolean;
  possessSubscription: boolean;
  outOfRequestNum: boolean;
  currentEnd: Date;
  tokenUsage: number;
  numOfRequest: number;
}

export enum SubscriptionLevel {
  Free = 'Free',
  Starter = 'Starter',
  Team = 'Team',
  Team_Pro = 'Team Pro',
  Enterprise = 'Enterprise',
}

export interface UserMeta {
  id: string;
  username: string;
  email: string;
  userType: UserType;
  firstname: string;
  lastname: string;
  phone: string;
  photoUrl: string;
  adminUser: UserMeta;
  nonAdminUsers: UserMeta[];
  supportedDatabases: DatabaseType[];
  supportedDatasets: DatasetType[];
}

export enum UserType {
  ADMIN = 'ADMIN',
  NON_ADMIN = 'NON_ADMIN',
  SUPER_ADMIN = 'SUPER_ADMIN',
}

export class UserEntity_DONTUSE {
  // USER AUTHENTICATION AND BASIC INFO
  id: string;
  username: string;
  password: string;
  email: string;
  type: AccountType;
  userType: UserType;

  // USER METADATA
  firstname: string;
  lastname: string;
  locale: string;
  picture: string;
}

export enum AccountType {
    FIRST = 'FIRST',
    GOOGLE = 'GOOGLE',
}


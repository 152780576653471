import {
  createDashboard,
  deleteDashboard,
  getAllDashboards,
  getDashboard,
  updateDashboard,
} from '../../../apis/dashboard/apis';
import {
  CreateDashboardDto,
  DashboardDto,
  UpdateDashboardDto,
} from '../../../apis/dashboard/interfaces';
import { useEffect, useState } from 'react';
import { Modal, Form, Button, Input, Radio } from 'antd';
import { DashboardList } from './DashboardList';
import { useNavigate } from 'react-router-dom';

export const DashboardTable = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [dashboards, setDashboards] = useState([]);
  const [editDashboardId, setEditDashboardId] = useState('');
  const [form] = Form.useForm();

  useEffect(() => {
    fecthDashboards();
  }, []);

  const navigate = useNavigate();

  const fecthDashboards = async () => {
    try {
      const dashboards = await getAllDashboards();
      setDashboards(dashboards);
    } catch (err) {
      console.error('Error fetching dashboards:', err);
    }
  };

  const handleAddDashboard = async (data: CreateDashboardDto) => {
    try {
      const newDashboard = await createDashboard(data);
      setDashboards([newDashboard, ...dashboards]);
    } catch (err) {
      console.error('Error creating dashboard:', err);
    }
  };

  const handleDeleteDashboard = async (dashboardId: string) => {
    try {
      await deleteDashboard(dashboardId);
      setDashboards(
        dashboards.filter((d: DashboardDto) => d.id !== dashboardId)
      );
    } catch (err) {
      console.error('Error deleting dashboard:', err);
    }
  };

  const handleEditDashboard = async (
    dashboardId: string,
    updateData: UpdateDashboardDto
  ) => {
    try {
      await updateDashboard(dashboardId, updateData);
      const theDashboard = await getDashboard(dashboardId);

      setDashboards(
        dashboards.map((d: DashboardDto) =>
          d.id === dashboardId ? theDashboard : d
        )
      );
    } catch (err) {
      console.error('Error updating dashboard:', err);
    }
  };

  const handleModalOpen = () => {
    setIsModalOpen(true);
  };

  const handleModalCancel = () => {
    setIsModalOpen(false);
    setEditDashboardId('');
    form.resetFields();
  };

  const handleModalComplete = async () => {
    try {
      const values = await form.validateFields();
      if (editDashboardId !== '') {
        await handleEditDashboard(
          editDashboardId,
          values as UpdateDashboardDto
        );
      } else {
        await handleAddDashboard(values as CreateDashboardDto);
      }
      setIsModalOpen(false);
      form.resetFields();
    } catch (error) {
      console.error('Error handling modal complete:', error);
    } finally {
      setEditDashboardId('');
    }
  };

  const handleTriggerEdit = (dashboardId: string) => {
    const theDashboard = dashboards.find(
      (d: DashboardDto) => d.id === dashboardId
    );
    form.setFieldsValue({
      name: theDashboard?.name,
      is_public: theDashboard?.is_public,
      layout: theDashboard?.layout,
    });
    setEditDashboardId(dashboardId);
    handleModalOpen();
  };

  const handleItemClick = (dashboardId: string) => {
    navigate(`/dashboard/${dashboardId}`);
  };

  return (
    <div>
      <Modal
        title="Create New Dashboard"
        open={isModalOpen}
        onCancel={handleModalCancel}
        onOk={handleModalComplete}
        okButtonProps={{ style: { backgroundColor: 'black', color: 'white' } }}
        cancelButtonProps={{
          style: { backgroundColor: 'black', color: 'white' },
        }}
      >
        <Form form={form} layout="vertical" name="create_dashboard_form">
          <Form.Item
            name="name"
            label="Dashboard Name"
            rules={[
              { required: true, message: 'Please input the dashboard name!' },
            ]}
          >
            <Input placeholder="Enter dashboard name" />
          </Form.Item>

          <Form.Item
            name="is_public"
            label="Public"
            rules={[{ required: true, message: 'Please select visibility!' }]}
          >
            <Radio.Group>
              <Radio value={true}>Public</Radio>
              <Radio value={false}>Private</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            name="layout"
            label="Layout"
            rules={[{ required: false, message: 'Please input the layout!' }]}
          >
            <Input
              placeholder="Enter layout"
              defaultValue={JSON.stringify({})}
            ></Input>
          </Form.Item>
        </Form>
      </Modal>

      <div className="flex justify-end mb-4">
        <Button
          type="primary"
          style={{ backgroundColor: 'black', color: 'white', margin: '10px' }}
          onClick={handleModalOpen}
        >
          Add new dashboard
        </Button>
      </div>
      <div className="px-6 h-[60vh] w-full">
        <div className="flex items-center gap-3 text-2xl font-bold font-mono">
          <span className="w-6 h-6 bg-database" />
          Dashboards
        </div>
        <div className={'bg-layer2 mt-3 w-full h-full rounded}'}>
          <div className="overflow-auto w-full h-full">
            <div className="flex gap-x-6 px-16 justify-between items-center w-full h-12 rounded-t bg-layer2 font-bold">
              <span className="w-1/2">Name</span>
              <span className="w-1/2">Created At</span>
              <span className="w-1/2">Modified At</span>
            </div>
            <div className="w-full border-t border-active_hover" />

            {dashboards.length ? (
              <DashboardList
                dashboards={dashboards}
                onClick={handleItemClick}
                onDelete={handleDeleteDashboard}
                onModify={handleTriggerEdit}
              />
            ) : (
              <div className="h-full flex-1 flex items-center justify-center text-semibold">
                <span className="text-2xl text-text_unselected">
                  No dashboards found
                </span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

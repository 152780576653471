import ReactGA from 'react-ga4';
import { config } from '../config';
export const setupGoogleAnalytics = () => {
  console.log('REACT_APP_GAID:' + config.gaId);
  ReactGA.initialize(config.gaId);
};

export const analyzePageGA = (
  page: string,
  title: string,
  hittype = 'pageView'
) => {
  ReactGA.send({
    hitType: hittype,
    page: page,
    title: title,
  });
};

// TODO: This is just a placeholder page for now
// TODO: Integrate this page with Dashboard.tsx later

import { useNavigate, useParams } from 'react-router-dom';
import { Dashboard } from './dashboard';
import { useEffect, useState } from 'react';
import { DashboardDto } from '../../../apis/dashboard/interfaces';
import { getDashboard } from '../../../apis/dashboard/apis';

export const DashboardItemDetail: React.FC = () => {
  const { id } = useParams();
  const [dashboard, setDashboard] = useState<DashboardDto | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch dashboard data
    const fetchDashboardData = async () => {
      try {
        const dashboardDto = await getDashboard(id);
        setDashboard(dashboardDto);
      } catch (err) {
        console.error(err);
      }
    };
    fetchDashboardData();
  }, [id]);

  const handleBackToDashboardList = () => {
    navigate('/dashboard');
  };

  return (
    <div className="p-5 font-sans flex-grow overflow-auto h-full">
      <div className="flex justify-between items-center w-full">
        <div className="mt-4 ml-4">
          <h1 className="text-2xl font-bold">{dashboard?.name}</h1>
        </div>
        <button
          onClick={handleBackToDashboardList}
          className="mt-4 mr-4 px-4 py-2 bg-black text-white rounded shadow hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-600 focus:ring-opacity-75 transition duration-300 ease-in-out"
        >
          Back To Dashboard List
        </button>
      </div>
      <div className="border-2 border-gray-300 p-4 mt-4 flex-grow overflow-auto h-full">
        <Dashboard dashboardId={id} />
      </div>
    </div>
  );
};

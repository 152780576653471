import { DashboardDto } from '../../../apis/dashboard/interfaces';
import { DashboardListItem } from './DashboardListItem';

interface DashboardListProps {
  dashboards: DashboardDto[];
  onClick: (dashboardId: string) => void;
  onDelete: (dashboardId: string) => void;
  onModify: (dashboardId: string) => void;
}
export const DashboardList: React.FC<DashboardListProps> = ({
  dashboards,
  onClick,
  onDelete,
  onModify,
}) => {
  return (
    <div className="dashboard-list">
      {dashboards.map((dashboard) => (
        <DashboardListItem
          key={dashboard.id}
          dashboard={dashboard}
          onClick={onClick}
          onDelete={onDelete}
          onModify={onModify}
        />
      ))}
    </div>
  );
};

import { Form, Input, Modal, Select } from 'antd';
import { FormInstance } from 'antd/lib';
import { useEffect, useState } from 'react';
import { getAllDashboards } from '../../../../../../apis/dashboard/apis';
import { DashboardDto } from '../../../../../../apis/dashboard/interfaces';

export interface WidgetCreationModalProps {
  open: boolean;
  form: FormInstance;
  handleModalCancel: () => void;
  handleModalComplete: () => void;
}

export const WidgetCreationModal: React.FC<WidgetCreationModalProps> = ({
  open,
  form,
  handleModalCancel,
  handleModalComplete,
}) => {
  const [dashboards, setDashboards] = useState([]);

  const fetchDashboards = async () => {
    try {
      const dashboards = await getAllDashboards();
      setDashboards(dashboards);
    } catch (err) {
      console.error('Error fetching dashboards:', err);
    }
  };

  useEffect(() => {
    fetchDashboards();
  }, []);

  return (
    <Modal
      title="Create New Widget"
      open={open}
      onCancel={handleModalCancel}
      onOk={handleModalComplete}
      okButtonProps={{ className: 'bg-black text-white' }}
      cancelButtonProps={{ className: 'bg-black text-white' }}
    >
      <Form form={form} layout="vertical" name="create_widget_form">
        <Form.Item
          name="name"
          label="Widget Name"
          rules={[{ required: true, message: 'Please input the widget name!' }]}
        >
          <Input placeholder="Enter widget name" />
        </Form.Item>

        <Form.Item
          name="dashboard"
          label="Dashboard"
          rules={[
            { required: true, message: 'Please select a dashboard instance!' },
          ]}
        >
          <Select placeholder="Select a dashboard">
            {dashboards.map((dashboard: DashboardDto) => (
              <Select.Option key={dashboard.id} value={dashboard.id}>
                {dashboard.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="userInput"
          label="User Input"
          rules={[{ required: false, message: 'Please provide user input!' }]}
        >
          <Input placeholder="Enter user input"></Input>
        </Form.Item>

        <Form.Item
          name="refreshRate"
          label="Refresh Rate"
          rules={[
            {
              required: false,
              message: 'Please provide refresh rate!',
              type: 'number',
            },
          ]}
        >
          <Input type="number" placeholder="Enter refresh rate"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};

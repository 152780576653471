import { useRecoilState, useRecoilValue } from 'recoil';
import {
  asyncDialogAtom,
  authAtom,
  curConversationAtom,
} from '../../../recoil/atoms/atoms';

import { useEffect, useState } from 'react';
import {
  CreateDialogDto,
  DialogDto,
  DialogStatusEnum,
} from '../../../apis/conversations/interfaces';
import { sendDialog, stopDialog } from '../../../apis/conversations/apis';
import Swal from 'sweetalert2';
import { Title } from './components/title';
import { startAsyncDialog, endAsyncDialog } from '../../../utils/dialog_async';
import DialogInput from './components/dialog_input';
import ChatHistory from './components/dialogs/ChatHistory';
import { regenerateQuery } from '../../../apis/conversations/apis';

export const Conversations = () => {
  const [curConversation, setCurConversation] =
    useRecoilState(curConversationAtom);
  const authInfo = useRecoilValue(authAtom);
  const [isLoading, setIsLoading] = useState(false);
  const [sentInput, setSentInput] = useState('');
  const [currentDialogId, setCurrentDialogId] = useState<string | null>(null);
  // const startedPreprocessing = useRef(false);
  const [, setAsyncDialog] = useRecoilState(asyncDialogAtom);

  let displayType: string;
  let dataSource;
  if (curConversation?.connectedDatasets?.length ?? 0 > 0) {
    displayType = 'dataset';
    dataSource = curConversation?.connectedDatasets?.[0];
  } else {
    displayType = 'database';
    dataSource = curConversation?.connectedDatabases?.[0];
  }

  // TODO: Clean up asyncDialog and stop long polling
  useEffect(() => {
    return () => {
      if (isLoading) {
        // call api to stop generating
      }
      setSentInput('');
      setIsLoading(false);
      setAsyncDialog(null);
      endAsyncDialog();
    };
  }, [curConversation]);

  // Start pre-processing when a new data entry conversation is created
  // Stopping first messages from server to client for data entry
  // useEffect(() => {
  //   if (
  //     curConversation &&
  //     curConversation.conversationType === ConversationType.DATAENTRY &&
  //     curConversation.chatHistory &&
  //     !isLoading &&
  //     !startedPreprocessing.current
  //   ) {
  //     setIsLoading(true);
  //     Swal.fire({
  //       title: 'Scanning dataset',
  //       text: 'Scanning the dataset for preprocessing may take a while, please wait',
  //       timer: 10000,
  //       timerProgressBar: true,
  //       showCloseButton: true,
  //       didOpen: () => {
  //         Swal.showLoading();
  //       },
  //     });
  //     startPreprocessing();
  //     startedPreprocessing.current = true;
  //   }
  // }, [curConversation, isLoading]);

  // const startPreprocessing = async () => {
  //   const createDialogDto: CreateDialogDto = {
  //     message: 'Start Preprocessing',
  //     conversationType: curConversation.conversationType,
  //     dataEntryRequest: {
  //       datasetName: curConversation.connectedDataset?.connJson?.key,
  //     },
  //   };
  //   sendDialog(curConversation.conversationId, createDialogDto)
  //     .then((res) => {
  //       const updatedDialogs = curConversation.chatHistory.concat(res);
  //       setCurConversation({
  //         ...curConversation,
  //         chatHistory: updatedDialogs,
  //       });
  //     })
  //     .catch((error) => {
  //       Swal.fire('Error!', error?.message ?? 'Something went wrong', 'error');
  //     })
  //     .finally(() => {
  //       setIsLoading(false);
  //     });
  // };

  // const submitDialogWithAgent = () => {
  //   return doSubmitDialog(true);
  // };

  const submitDialog = (userInput: string, useAgent = false) => {
    // return doSubmitDialog(false);
    return doSubmitDialogAsync(userInput, useAgent, true);
  };

  const doSubmitDialogAsync = (
    userInput: string,
    useAgent: boolean,
    isAsync: boolean
  ) => {
    const createDialogDto: CreateDialogDto = {
      message: userInput,
      conversationType: curConversation.conversationType,
      text2SqlRequest: {
        executeSQL: true,
        useAgent: useAgent,
      },
      isAsync: isAsync,
    };

    setIsLoading(true);
    setSentInput(userInput);

    const onFetchSuccess = (res: DialogDto) => {
      setAsyncDialog(res);
    };

    const onFetchError = (error) => {
      Swal.fire({
        title: 'Error',
        text: error?.data ?? 'Something went wrong',
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
    };

    const onFetchFinish = (res) => {
      const updatedDialogs = curConversation.chatHistory.concat(res);
      setCurConversation({
        ...curConversation,
        chatHistory: updatedDialogs,
      });
    };

    sendDialog(curConversation.conversationId, createDialogDto)
      .then(async (res) => {
        setCurrentDialogId(res.dialogId); // Store the dialogId
        onFetchSuccess(res);
        if (
          res.dialogStatus &&
          res.dialogStatus === DialogStatusEnum.IN_PROGRESS
        ) {
          await startAsyncDialog(
            curConversation.conversationId,
            res.dialogId,
            onFetchError,
            onFetchSuccess,
            onFetchFinish,
            5
          );
        } else {
          const updatedDialogs = curConversation.chatHistory.concat(res);
          setCurConversation({
            ...curConversation,
            chatHistory: updatedDialogs,
          });
        }
      })
      .catch((error) => {
        onFetchError(error);
      })
      .finally(() => {
        setIsLoading(false);
        setAsyncDialog(null);
      });
  };

  // Handle stopping the dialog
  const handleStop = async () => {
    try {
      if (!currentDialogId) {
        throw new Error('No dialog is currently being generated.');
      }

      await stopDialog(curConversation.conversationId, currentDialogId);
      Swal.fire({
        title: 'Stopped',
        text: 'The dialog has been successfully stopped.',
        icon: 'info',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
      setIsLoading(false); // Stop the loading indicator
      setCurrentDialogId(null); // Reset the dialogId
    } catch (error) {
      Swal.fire({
        title: 'Error',
        text: 'Failed to stop the dialog.',
        icon: 'error',
        toast: true,
        position: 'top-end',
        timer: 3000,
        showConfirmButton: false,
      });
    }
  };

  const regenerateQueryAsync = async (
    updatedQuery: string,
    dialogId: string
  ): Promise<void> => {
    setIsLoading(true);

    try {
      const res = await regenerateQuery(
        curConversation.conversationId,
        dialogId,
        {
          message: updatedQuery,
          conversationType: curConversation.conversationType,
          text2SqlRequest: {
            executeSQL: true,
            useAgent: false,
          },
          isAsync: true,
        }
      );

      const updatedDialogs = curConversation.chatHistory.concat(res);
      setCurConversation({
        ...curConversation,
        chatHistory: updatedDialogs,
      });

      Swal.fire({
        title: 'Success',
        text: 'Query regenerated successfully!',
        icon: 'success',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
    } catch (error) {
      const errorMessage = error?.data ?? 'Something went wrong';
      Swal.fire({
        title: 'Error',
        text: errorMessage,
        icon: 'error',
        toast: true,
        position: 'top-end',
        showConfirmButton: false,
        timer: 3000,
      });
      console.error('Error regenerating query:', error);
    } finally {
      setIsLoading(false);
    }
  };

  if (!curConversation || !authInfo) return null;

  return (
    <div className="relative flex flex-col overflow-y-hidden h-full w-full">
      <Title displayType={displayType} datasource={dataSource} />
      <ChatHistory
        dialogs={curConversation.chatHistory}
        sentInput={sentInput}
        isLoading={isLoading}
        username={authInfo.username}
        regenerateQueryAsync={regenerateQueryAsync}
      />

      {/* {curConversation &&
          curConversation.conversationType === ConversationType.DATAENTRY &&
          !isLoading && (
             */}

      {!isLoading && (
        <DialogInput
          key={curConversation.conversationId}
          initInput={sentInput}
          placeholder={
            curConversation?.chatHistory?.length === 0
              ? "Let's start a conversation"
              : ''
          }
          handleSubmitDialog={submitDialog}
          followUps={
            curConversation?.chatHistory?.at(
              curConversation.chatHistory.length - 1
            )?.followUps ?? []
          }
        />
      )}

      {isLoading && (
        <button
          onClick={handleStop}
          style={{
            position: 'absolute',
            right: '20px',
            bottom: '20px',
            backgroundColor: 'black',
            color: 'white',
            padding: '10px',
            borderRadius: '50%',
            fontSize: '24px',
            width: '50px',
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          title="Stop Processing"
        >
          &#x23F9;
        </button>
      )}
    </div>
  );
};

import React, { useState, useEffect } from 'react';
import GridLayout from 'react-grid-layout';
import { Layout } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { DashboardWidget } from './DashboardWidget';
import { WidgetConfigDto } from '../../../apis/dashboard/interfaces';
import {
  updateDashboard,
  findOneWithWidgetConfigs,
  deleteWidget,
} from '../../../apis/dashboard/apis';
import Swal from 'sweetalert2';

interface DashboardProps {
  dashboardId: string;
}

export const Dashboard: React.FC<DashboardProps> = ({ dashboardId }) => {
  const [widgetConfigs, setWidgetConfigs] = useState<WidgetConfigDto[]>([]);
  const [layout, setLayout] = useState<Layout[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [ifError, setIfError] = useState<boolean>(false);

  useEffect(() => {
    const fetchDashboardData = async () => {
      setLoading(true);
      try {
        const res = await findOneWithWidgetConfigs(dashboardId);
        const { dashboard: dashboardDto, configs } = res;
        setWidgetConfigs(configs);

        //TODO: Implement layout in the next phase
        if (!dashboardDto.layout || dashboardDto.layout.length === 0) {
          const defaultLayout = configs.map((widget, index) => ({
            i: widget.widgetId,
            x: (index * 4) % 12,
            y: Math.floor(index / 3) * 4,
            w: 4,
            h: 4,
          }));
          setLayout(defaultLayout);
        } else {
          setLayout(dashboardDto.layout);
        }
      } catch (err) {
        setIfError(true);
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, [dashboardId]);

  const handleLayoutChange = async (newLayout: Layout[]) => {
    try {
      await updateDashboard(dashboardId, { layout: newLayout });
    } catch (err) {
      console.error('Failed to update layout:', err);
    }
    setLayout(newLayout);
  };

  const handleDeleteWidget = async (widgetId: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this widget!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it',
    })
      .then(async (result) => {
        if (result.isConfirmed) {
          await deleteWidget(widgetId);
          setWidgetConfigs((prev) =>
            prev.filter((widget) => widget.widgetId !== widgetId)
          );
        }
      })
      .catch((err) => {
        Swal.fire('Error', 'Failed to delete widget: ' + err.message, 'error');
      });
  };

  if (loading) {
    return (
      <div className="h-full w-full p-4 flex justify-center items-center">
        <h1 className="text-2xl font-bold">Loading...</h1>
      </div>
    );
  }

  if (ifError) {
    return (
      <div className="h-full w-full p-4 flex justify-center items-center">
        <h1 className="text-2xl font-bold">
          There is an error loading widgets
        </h1>
      </div>
    );
  }

  if (widgetConfigs.length === 0) {
    return (
      <div className="h-full w-full p-4 flex justify-center items-center">
        <h1 className="text-2xl font-bold">No widgets found</h1>
      </div>
    );
  }

  return (
    <div className="h-full w-full p-4">
      <GridLayout
        className="layout"
        layout={layout}
        onLayoutChange={handleLayoutChange}
        cols={12}
        rowHeight={30}
        width={1200}
        draggableHandle=".widget-handle"
      >
        {widgetConfigs.map((widget) => (
          <div key={widget.widgetId}>
            <DashboardWidget
              widget={widget}
              onWidgetDelete={handleDeleteWidget}
            />
          </div>
        ))}
      </GridLayout>
    </div>
  );
};

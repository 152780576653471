// Define the runtime config interface
interface RuntimeConfig {
  APIBASE: string;
  APIPORT: string;
  REACT_APP_GOOGLEID: string;
  REACT_APP_SHOW_WIP: boolean;
  REACT_APP_GAID: string;
  REACT_APP_AUTH0_CLIENT_ID: string;
  REACT_APP_AUTH0_DOMAIN: string;
  REACT_APP_NODE_ENV: 'development' | 'production' | 'ci';
}

// Add runtime config to Window interface
declare global {
  interface Window {
    __RUNTIME_CONFIG__?: RuntimeConfig;
  }
}

// Default config values
const defaultConfig: RuntimeConfig = {
  APIBASE: 'http://localhost',
  APIPORT: '3000',
  REACT_APP_GOOGLEID: '',
  REACT_APP_SHOW_WIP: false,
  REACT_APP_GAID: '',
  REACT_APP_AUTH0_CLIENT_ID: '',
  REACT_APP_AUTH0_DOMAIN: '',
  REACT_APP_NODE_ENV: 'development'
};

// Get config with defaults
const runtimeConfig = window.__RUNTIME_CONFIG__ ?? defaultConfig;

// Exported config object with consistent naming
export const config = {
  apiBase: runtimeConfig.APIBASE,
  apiPort: runtimeConfig.APIPORT,
  googleId: runtimeConfig.REACT_APP_GOOGLEID,
  showWip: runtimeConfig.REACT_APP_SHOW_WIP,
  gaId: runtimeConfig.REACT_APP_GAID,
  auth0: {
    clientId: runtimeConfig.REACT_APP_AUTH0_CLIENT_ID,
    domain: runtimeConfig.REACT_APP_AUTH0_DOMAIN
  },
  nodeEnv: runtimeConfig.REACT_APP_NODE_ENV
} as const;

// Helper function to get the full API URL
export const getApiUrl = () => {
  const base = config.apiBase.endsWith('/') ? config.apiBase.slice(0, -1) : config.apiBase;
  return `${base}:${config.apiPort}`;
};

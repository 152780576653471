import { useEffect, useMemo, useRef } from 'react';
import Plotly from 'plotly.js-dist';

interface AIChartProps {
  AIChart: string;
}

export const AIChartV1: React.FC<AIChartProps> = ({ AIChart }) => {
  const plotContainer = useRef<HTMLDivElement>(null);
  const { x, y, config, layout } = JSON.parse(AIChart);

  const data = useMemo(() => {
    return y.map((yValue, idx: number) => ({
      x: x,
      y: yValue,
      ...config[idx],
    }));
  }, [x, y, config]);

  useEffect(() => {
    if (plotContainer.current) {
      Plotly.newPlot(plotContainer.current, data, layout);
    }
  }, [data, layout]);

  return (
    <div className="w-full h-full">
      <div ref={plotContainer} className="w-full h-full" />
    </div>
  );
};

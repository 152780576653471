import { DashboardDto } from '../../../apis/dashboard/interfaces';
import Swal from 'sweetalert2';

interface DashboardListItemProps {
  dashboard: DashboardDto;
  onClick: (dashboardId: string) => void;
  onDelete: (dashboardId: string) => void;
  onModify: (dashboardId: string) => void;
}
export const DashboardListItem: React.FC<DashboardListItemProps> = ({
  dashboard,
  onClick,
  onDelete,
  onModify,
}) => {
  const handleDelete = async (dashboardId: string) => {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#d33',
      cancelButtonColor: '#3085d6',
      confirmButtonText: 'Yes, delete it!',
    }).then((result) => {
      if (result.isConfirmed) {
        onDelete(dashboardId);
        Swal.fire('Deleted!', 'Your file has been deleted.', 'success');
      }
    });
  };

  return (
    <div
      className="dashboard-list-item flex items-center justify-between p-2 border border-gray-300 rounded mb-2 cursor-pointer"
      onClick={() => onClick(dashboard.id)}
    >
      <h2 className="m-0">{dashboard.name}</h2>

      <h2 className="m-0 mx-2">
        {new Date(dashboard.created_at).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })}
      </h2>

      <h2 className="m-0 mx-2">
        {new Date(dashboard.updated_at).toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })}
      </h2>

      <div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(dashboard.id);
          }}
          className="mr-2 px-4 py-2 bg-red-500 text-white border-none rounded cursor-pointer"
        >
          Delete
        </button>

        <button
          onClick={(e) => {
            e.stopPropagation();
            onModify(dashboard.id);
          }}
          className="px-4 py-2 bg-green text-white border-none rounded cursor-pointer"
        >
          Edit
        </button>
      </div>
    </div>
  );
};

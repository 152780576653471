import { SubscriptionResponse, SubscriptionStatus } from './interfaces';
import { instance } from '../base/instance';

export async function getSubscription(): Promise<SubscriptionResponse> {
  return (await instance.get('/billing/subscription')).data;
}

export async function getSubscriptionStatus(): Promise<SubscriptionStatus> {
  return (await instance.get('/billing/subscription/status')).data;
}

export async function cancelSubscription(): Promise<void> {
  return (await instance.delete('/billing/subscription')).data;
}

export async function createCheckoutSession(priceId: string): Promise<{ sessionId: string }> {
  return (await instance.post('/billing/create-checkout-session', { priceId })).data;
}

export async function createPortalSession(): Promise<{ url: string }> {
  return (await instance.post('/billing/create-portal-session')).data;
}


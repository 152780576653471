import React from 'react';
import { AIChartV1 } from './components/WidgetChart';
import { WidgetConfigDto } from '../../../apis/dashboard/interfaces';

interface DashboardWidgetProps {
  widget: WidgetConfigDto;
  onWidgetDelete: (widgetId: string) => void;
}

export const DashboardWidget: React.FC<DashboardWidgetProps> = ({
  widget,
  onWidgetDelete,
}) => {
  const config = JSON.parse(widget.config);

  // TODO: have to drag to delete the widget
  return (
    <div className="bg-white rounded-lg shadow h-full relative">
      <div className="absolute top-0 right-0 m-2">
        <button
          className="text-red-500 hover:text-red-700"
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
            onWidgetDelete(widget.widgetId);
          }}
        >
          Delete
        </button>
      </div>
      <div className="widget-handle p-2 cursor-move border-b flex justify-between items-center">
        <h3 className="font-semibold">{config.layout.title || 'Chart'}</h3>
      </div>
      <div className="p-4 h-[calc(100%-40px)]">
        <AIChartV1 AIChart={widget.config} />
      </div>
    </div>
  );
};
